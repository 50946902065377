import { getCurrentInstance } from '@vue/composition-api'
import {
  useRouter, getDefaultProductImage, assignPayloadProduct, formatObject,
  getWishlistTitle,
} from '@/@core/utils/utils'
import { apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import store from '@/store'
import analytics, { getSelectionOrWishlistMixpanelObj } from '@/@core/utils/analytics'
import constants, { wishlistAction } from '@/constants'
import {
 ADD_PRODUCT_TO_CART, ADD_PRODUCT_TO_WISHLIST, REMOVE_MULTIPLE_WISHLIST_PRODUCTS, REMOVE_PRODUCT_FROM_CART, REMOVE_WISHLIST_PRODUCT, UPDATE_CART_ITEMS_COUNT,
} from '@/store/modules/shop.module'
import { SHOW_WISHLIST_NAV_INDICATOR } from '@/store/modules/notification.module'

function isRetailer() {
  return store.getters.isRetailer
}

export const useEcommerce = () => {
  // eslint-disable-next-line arrow-body-style
  const addProductInWishlist = payload => {
    return store.dispatch(ADD_PRODUCT_TO_WISHLIST, { payload })
  }

  // eslint-disable-next-line arrow-body-style
  const removeProductFromWishlist = (productId, wishlistId) => {
    return store.dispatch(REMOVE_WISHLIST_PRODUCT, {
      productId,
      wishlistId,
    })
  }

  const removeMultipleProductsFromWishlist = (products, wishlistId) => store.dispatch(REMOVE_MULTIPLE_WISHLIST_PRODUCTS, {
    products,
    wishlistId,
  })

  // eslint-disable-next-line arrow-body-style
  const addProductInCart = async (payload = {}, params = {}) => {
    return store.dispatch(ADD_PRODUCT_TO_CART, { payload, params })
  }

  // eslint-disable-next-line arrow-body-style
  const removeProductFromCart = async productId => {
    return store.dispatch(REMOVE_PRODUCT_FROM_CART, { productId })
  }

  const REMOVE_PRODUCT_FROM_CART_MSG = 'Removed product from cart!'

  return {
    addProductInWishlist,
    removeProductFromWishlist,
    removeMultipleProductsFromWishlist,
    addProductInCart,
    removeProductFromCart,
    REMOVE_PRODUCT_FROM_CART_MSG,
  }
}

export const useEcommerceUi = () => {
  const { router } = useRouter()

  // ACTIONS for wishlist

  const removeFromWishlist = async (products, wishlistId, collectionName) => {
    const { removeMultipleProductsFromWishlist } = useEcommerce()
    const payload = { products: [] }
    let notInWish = 0

    products.forEach(product => {
      if (!product.isInWishlist) {
        notInWish++
      } else {
        payload.products.push({ productId: product._id })
      }
    })

    if (payload.products.length === 0) {
      apiToastWarning('The selected products are not in the Wishlist')
      return products
    }

    if (notInWish > 0) {
      apiToastWarning('Some products are not in the Wishlist')
    }

    analytics.track(
      constants.TRACKS.ACTIONS.RETAILER_REMOVES_SELECTED_ITEMS_FROM_WISHLIST,
      formatObject({ collectionName, selectedProductsCount: payload.products.length }),
    )

    try {
      await removeMultipleProductsFromWishlist(payload.products, wishlistId)
      products.forEach(product => {
        product.isInWishlist = false
      })
      apiToastSuccess('Removed product from wishlist!')
    } catch (err) {
      apiToastWarning(err)
    }

    return products
  }

  const removeInWishlist = async (products, wishlist, isBrand) => {
    const { removeMultipleProductsFromWishlist } = useEcommerce()
    const trackAction = isBrand
      ? constants.TRACKS.ACTIONS.BRAND_REMOVES_SELECTED_ITEMS_FROM_SELECTION
      : constants.TRACKS.ACTIONS.RETAILER_REMOVES_PRODUCTS_FROM_WISHLIST

    analytics.track(trackAction, formatObject(getSelectionOrWishlistMixpanelObj(wishlist, isBrand, { selectedProductsCount: products.length })))

    const payload = { products: [] }

    products.forEach(product => {
      payload.products.push({ productId: product.productId })
    })

      try {
        await removeMultipleProductsFromWishlist(payload.products, wishlist._id)
        apiToastSuccess(`Removed products from ${getWishlistTitle(!isBrand)}!`)
      } catch (err) {
        apiToastWarning(err)
      }

    return payload
  }

  const addToWishlist = async (product, wishlistId, wishlistName, quantity, items, collectionName) => {
    const { addProductInWishlist } = useEcommerce()
    const payload = { products: [] }

    if (wishlistName) {
      payload.name = wishlistName
    }

    if (wishlistId) {
      payload.wishlistId = wishlistId
    }

    if (Array.isArray(product)) {
      product.forEach(p => {
        payload.products.push({ productId: p._id })
      })
    } else {
      payload.products.push({ productId: product._id })
    }

    if (typeof quantity !== 'undefined') {
      quantity = parseInt(quantity)
      if (!isNaN(quantity) && quantity > 0) payload.quantity = quantity
    }

    if (Array.isArray(items)) {
      payload.items = items
    }

    try {
      await addProductInWishlist(payload)

      if (Array.isArray(product)) {
        analytics.track(
          store.getters.isBrand
            ? constants.TRACKS.ACTIONS.BRAND_MOVES_SELECTED_ITEMS_TO_SELECTION
            : constants.TRACKS.ACTIONS.RETAILER_MOVES_SELECTED_ITEMS_TO_WISHLIST,
          formatObject(getSelectionOrWishlistMixpanelObj({ collectionName }, store.getters.isBrand, { selectedProductsCount: product.length }, true)),
        )
      } else {
        product.isInWishlist = true
      }
      store.commit(SHOW_WISHLIST_NAV_INDICATOR)
      apiToastSuccess(`Added product to ${getWishlistTitle(isRetailer())}!`)
    } catch (err) {
      apiToastWarning(err)
    }

    return product
  }

  const toggleProductInWishlist = async ({
 product, wishlistId, quantity, items, action, wishlistName, wishlist, collectionName,
}) => {
    try {
      if (!Array.isArray(product)) {
        product = [product]
      }
      if (action === wishlistAction.REMOVE_FROM_WISHLIST) {
        return removeFromWishlist(product, wishlistId, collectionName)
      } else if (action === wishlistAction.REMOVE_IN_WISHLIST) {
        return await removeInWishlist(product, wishlist, store.getters.isBrand)
      } else {
        return addToWishlist(product, wishlistId, wishlistName, quantity, items, collectionName)
      }
    } catch (err) {
      apiToastWarning(err)
    }
  }

  // ACTIONS for Cart

  const vm = getCurrentInstance().proxy

  const handleCartActionClick = async ({
    product,
    quantity,
    items,
  }, force) => {
    force = force || false
    const { addProductInCart } = useEcommerce()
    try {
      if (!force && product.isInCart) {
        analytics.track(constants.TRACKS.ACTIONS.VIEW_IN_CART, formatObject(product))
        router.push({ name: 'checkout' })
      }
      else {
        const payload = {
          products: [],
        }
        // isCarton = true, send productId with quantity
        if (product.isCarton) {
          if (typeof quantity !== 'undefined') {
            quantity = parseInt(quantity)
            if (isNaN(quantity) || quantity < 1) {
              apiToastWarning('Please enter a valid quantity!')
              return product
            }
          }
          else {
            quantity = 1
          }
          payload.products.push({
            productId: product._id,
            quantity,
          })
        }
        // isCarton = false, send productId, skuId & value separated
        else if (Array.isArray(items) && items.length > 0) {
          const payloadProductsItems = []
          let hasValue = false
          items.forEach(item => {
            for (const k in item) {
              if (item.hasOwnProperty(k) && k !== 'color') {
                payloadProductsItems.push({
                  sku: item[k].skuId,
                  value: item[k].value,
                })
                if (item[k].value > 0) {
                  hasValue = true
                }
              }
            }
          })
          const payloadProducts = [{
            productId: product._id,
            items: payloadProductsItems,
          }]
          if (!hasValue) {
            throw Error('Please enter valid values for variants!')
          }
          else {
            payload.products = payloadProducts
          }
        }
        else if (product.isCarton) {
          payload.products.push({
            productId: product._id,
          })
        }
        else {
          payload.products = assignPayloadProduct(product.items, product._id)
        }
        return await addProductInCart(payload)
          .then(res => {
            product.isInCart = true
            // Update cart items count
            store.commit(UPDATE_CART_ITEMS_COUNT, store.state.shop.cartItemsCount + 1)
            apiToastSuccess(res.data.message || 'Add product to cart!')
            analytics.track(constants.TRACKS.ACTIONS.ADD_TO_CART, formatObject(product))
            return product
          })
          .catch(err => {
            // when user add product from another collection to cart
            if (err.response.status === 425) {
              if (vm) {
                vm.$root.$cartConflict.value = {
                  product,
                  payload,
                  message: err.response.data.message || err.message,
                }
              }
            }
            else {
              apiToastWarning(err)
            }
            return null
          })
      }
    } catch (err) {
      apiToastWarning(err)
    }
  }

  // ACTION for cart through wishlist

  const handleWishlistCartActionClick = async (product, wishlist) => {
    const {
      addProductInCart,
      // removeProductFromWishlist
    } = useEcommerce()

    const productIsInCart = product?.isInCart || product?.productInfo?.isInCart
    if (productIsInCart) {
      router.push({ name: 'checkout' })
    }
    else {
      const payload = {
        products: [],
      }
      if (product.productInfo.isCarton) {
        payload.products.push({
          productId: product.productId,
        })
      }
      else {
        payload.products = assignPayloadProduct(product.productInfo.items, product.productInfo._id)
      }

      return await addProductInCart(payload)
        .then(res => {
          product.isInCart = true
          // Update cart items count
          store.commit(UPDATE_CART_ITEMS_COUNT, store.state.shop.cartItemsCount + 1)
          apiToastSuccess(res.data.message || 'Add product to cart!')

          // product.isInWishlist = false
          // removeProductFromWishlistUi(product)
          analytics.track(constants.TRACKS.ACTIONS.ADD_TO_CART, formatObject(product))
          analytics.track(constants.TRACKS.ACTIONS.RETAILER_MOVES_FROM_WISHLIST_TO_CART, formatObject(getSelectionOrWishlistMixpanelObj(wishlist, false)))
          return product
        })
        .catch(err => {
          if (err.response.status === 425) {
            if (vm) {
              vm.$root.$cartConflict.value = {
                product,
                message: err.response.data.message || err.message,
              }
            }
          }
          else {
            apiToastWarning(err)
          }
          return null
        })
    }
  }

  // ACTION Multiple add cart
  const addMultipleItemsInCart = async (product, queryParams = {}, deselectedProducts = []) => {
    const { addProductInCart } = useEcommerce()

    const inCartCount = removeItemsInCart(product)

    if (inCartCount > 0) {
      apiToastWarning('Some items are already in cart')
    }

    if (product.length === 0) {
      apiToastWarning('Items already in cart')
      return product
    }

    const payload = buildPayload(product)

    if (deselectedProducts.length) {
      payload.deselectedProducts = deselectedProducts
    }

    try {
      const res = await addProductInCart(payload, queryParams)

      markItemsAsInCart(product)
      updateCartItemsCount(queryParams, product.length)

      apiToastSuccess(res.data.message || 'Add product to cart!')
      analytics.track(constants.TRACKS.ACTIONS.ADD_MULTI_CART, { Count: product.length })

      return product
    } catch (err) {
      handleCartAdditionError(product,
        payload,
        queryParams, err)
      return null
    }
  }

  function removeItemsInCart(product) {
    let inCartCount = 0
    for (let i = product.length - 1; i >= 0; i--) {
      if (product[i].isInCart) {
        product.splice(i, 1)
        inCartCount++
      }
    }
    return inCartCount
  }

  function buildPayload(product) {
    const payload = {
      products: [],
    }

    product.forEach(products => {
      const productId = products._id ? products._id : products.productId

      if (products.isCarton || products.productInfo?.isCarton) {
        payload.products.push({ productId })
      } else {
        const items = products._id ? products.items : products.productInfo.items
        const prod = assignPayloadProduct(items, productId)
        payload.products.push(...prod)
      }
    })

    return payload
  }

  function markItemsAsInCart(product) {
    product.forEach(products => {
      products.isInCart = true
    })
  }

  function updateCartItemsCount(queryParams, count) {
    const totalProductsCount = queryParams?.isSelectedAllProducts
      ? store.state.shop.totalProductsCount
      : store.state.shop.cartItemsCount + count

    store.commit(UPDATE_CART_ITEMS_COUNT, totalProductsCount)
  }

  function handleCartAdditionError(product,
    payload,
    queryParams, err) {
    if (err.response?.status === 425) {
      handleCartConflictError(product,
        payload,
        queryParams, err)
    } else {
      apiToastWarning(err)
    }
  }

  function handleCartConflictError(product,
    payload,
    queryParams, err) {
    if (vm) {
      vm.$root.$cartConflict.value = {
        product,
        payload,
        queryParams,
        message: err.response.data.message || err.message,
      }
    }
  }

  const parseProductImage = product => {
    if (product?.thumbnail) {
      return product.thumbnail
    }
    else if (product?.images?.length && product?.images[0]) {
      return product.images[0]
    }
    else {
      return getDefaultProductImage()
    }
  }

  const parseProductImages = (product = {}) => {
    if (product?.images?.length) {
      return product.images.map(img => img || getDefaultProductImage())
    }
    return [
      getDefaultProductImage(),
      getDefaultProductImage(),
      getDefaultProductImage(),
    ]
  }

  const isInWishlist = product => product.isInWishlist

  return {
    toggleProductInWishlist,
    handleCartActionClick,
    handleWishlistCartActionClick,
    addMultipleItemsInCart,
    parseProductImage,
    parseProductImages,
    isInWishlist,
    getDefaultProductImage,
    assignPayloadProduct,
  }
}
