<template>
  <!-- Checkout Options -->
  <div>
    <b-row>
      <b-col class="col-12 col-sm-6 mb-1 mb-sm-0 d-flex">
        <b-button
          v-b-tooltip.hover.bottom.v-primary
          variant="outline-secondary-black"
          class="px-1"
          title="Back to Collection"
          link
          :to="{ name: 'collection/products', params: { collectionId: cartList[0].collectionId }}"
        >
          <feather-icon
            icon="ChevronLeftIcon"
            size="16"
          />
        </b-button>
        <b-button
          variant="secondary-black"
          class="ml-1 d-flex align-items-center"
          @click="onClickBuyingSummary"
        >
          <feather-icon
            icon="MenuIcon"
            size="16"
          />
          <div class="ml-50">
            Buying Summary
          </div>
        </b-button>
      </b-col>
      <b-col class="col-12 col-sm-6 d-flex justify-content-sm-end">
        <b-button
          variant="outline-secondary-black"
          class="mr-1"
          @click="onClickImportCart"
        >
          <import-icon class="align-top" />
          Import
        </b-button>
        <b-button
          class="border-10"
          variant="outline-secondary-black"
          @click="openExportCartModal"
        >
          <export-icon class="align-top" />
          Export cart for all stores
        </b-button>
      </b-col>
    </b-row>

    <!-- Buying Summary Modal -->
    <template v-if="cartList.length">
      <buying-summary-modal
        :cart-list="cartList"
        :cart-values-list="cartValuesList"
        :total-order-value="totalOrderValue"
      />
      <select-stores-modal
        modal-id="modal_export_cart_stores"
        modal-title="Export cart of all stores"
        ok-btn-text="Export"
        :store-list="cartValuesList"
        :is-downloading="isExporting"
        @on-click-download="onClickExportCart"
      />
    </template>
  </div>
</template>

<script>
import ExportIcon from '@/@core/assets/svg-components/ExportIcon.vue'
import ImportIcon from '@/@core/assets/svg-components/ImportIcon.vue'
import {
  BButton, BCol, BRow, VBTooltip, VBModal,
} from 'bootstrap-vue'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import constants, { DOWNLOAD_TYPES } from '@/constants'
import { apiToastError, apiToastSuccess } from '@/@core/utils/toast'
import { EXPORT_CART_MULTIPLE } from '@/store/modules/shop.module'
import analytics from '@/@core/utils/analytics'
import { UPDATE_CARTS } from '@/store/modules/checkout-v2.module'
import NotificationMixin from '@/views/mixins/NotificationMixin.vue'
import SelectStoresModal from './components/SelectStoresModal.vue'
import BuyingSummaryModal from './BuyingSummaryModal.vue'

const { CURRENCY_CONVERSIONS } = c

const CART_WITH_MULTIPLE_STORES = constants.TRACKS.ACTIONS.CART_WITH_MULTIPLE_STORES

export default {
  name: 'CartActions',
  components: {
    BRow,
    BCol,
    BButton,
    ImportIcon,
    ExportIcon,
    BuyingSummaryModal,
    SelectStoresModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  mixins: [NotificationMixin],
  props: {
    cartList: {
      type: Array,
      required: true,
    },
    cartValuesList: {
      type: Array,
      required: true,
    },
    totalOrderValue: {
      type: Object,
      default: () => ({
        totalCartsValue: 0,
        totalCartsUnits: 0,
        totalCartsRRP: 0,
        currency: CURRENCY_CONVERSIONS.USD.SYMBOL,
      }),
    },
  },
  data() {
    return {
      isExporting: false,
    }
  },
  methods: {
    onClickBuyingSummary() {
      this.$bvModal.show('buying_summary_v_2')
      analytics.track(CART_WITH_MULTIPLE_STORES.RETAILER_CLICKED_BUYING_SUMMARY_BUTTON, { cartValuesList: this.cartValuesList })
    },
    onClickImportCart() {
      this.$bvModal.show('import_cart_modal')
      analytics.track(CART_WITH_MULTIPLE_STORES.RETAILER_CLICKED_IMPORT_CART_BUTTON)
    },
    openExportCartModal() {
      this.$bvModal.show('modal_export_cart_stores')
      analytics.track(CART_WITH_MULTIPLE_STORES.RETAILER_CLICKED_EXPORT_CART_BUTTON)
    },
    async onClickExportCart(selectedStores = []) {
      try {
        await this.$store.dispatch(UPDATE_CARTS)
      } catch (err) {
        apiToastError(err)
        return
      }
      this.isExporting = true
      const downloadType = DOWNLOAD_TYPES.MULTIPLE_CART_EXPORT.VALUE
      const payload = {
        downloadType,
        respectiveIds: selectedStores.map(store => store.storeId),
      }

      this.$store.dispatch(EXPORT_CART_MULTIPLE, { payload }).then(res => {
        this.isExporting = false
        this.$bvModal.hide('modal_export_cart_stores')
        apiToastSuccess(res.data.message)
        analytics.track(CART_WITH_MULTIPLE_STORES.RETAILER_EXPORT_CART_SUCCESSFULLY, { payload })
        this.notifyDownloadsNavMenu()
      })
      .catch(err => {
        apiToastError(err)
        this.isExporting = false
        analytics.track(CART_WITH_MULTIPLE_STORES.RETAILER_EXPORT_CART_ERROR, err?.response)
      })
    },
  },
}
</script>
